import { gql } from '@apollo/client';

export const GET_HELLO_WORLD = gql`
  query getHelloWorld {
    getHelloWorld {
      message
    }
  }
`;

export const MUTATION_UPDATE_BACKLOG = gql`
  mutation updateBacklog($input: DefaultInput!) {
    updateBacklog(input: $input) {
      errors {
        message
      }
      status
    }
  }
`;

export const MUTATION_ADD_LEADS = gql`
  mutation addLeads($input: LeadsInput!) {
    addLeads(input: $input) {
      errors {
        message
        code
      }
      status
      leadPosts {
        title
        description
        score
        keywords
        names
        url
        error
        text
        dateCreated
        dateUpdated
        orgId
        userId
        id
        processed
        scoreAI
        namesAI {
          name
          desc
          score
        }
        tokensAI
        alert
      }
    }
  }
`;

export const MUTATION_PROCESS_LEAD = gql`
  mutation processLead($input: LeadsInput!) {
    processLead(input: $input) {
      errors {
        message
        code
      }
      status
    }
  }
`;

export const MUTATION_DELETE_LEAD = gql`
  mutation deleteLead($input: LeadsInput!) {
    deleteLead(input: $input) {
      errors {
        message
        code
      }
      status
    }
  }
`;

export const QUERY_ITEMS = gql`
  query queryItems($input: DefaultInput!) {
    queryItems(input: $input) {
      errors {
        message
        code
      }
      resourceTypes {
        id
        name
        rate
        orgId
        salary
        freelance
      }
      users {
        displayName
        email
        id
        orgId
        salary
        resourceType
        planned
        roles
        dateUpdated
        inactive
      }
      projects {
        id
        status
        orgId
        floorArea
        name
        shortName
        code
        color
        dateCreated
        dateUpdated
        startDate
        profitPercentage
        constructionBudget
        version
        CSIs
        phases {
          name
          id
          active
          contractBudget
          fee
          duration
          startWeek
          startDate
          billable
          billType
          deleted
          resources {
            resourceType
            hoursPerWeek
          }
        }
        team {
          id
          resourceType
        }
        legacyTeam
        slug
        multiplier
        lockedKeys
        resourceTypes {
          id
          name
          rate
          salary
          freelance
        }
      }
      organization {
        multiplier
        name
        emailDomain
        id
      }
      hours {
        id
        submitted
        orgId
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
            note
          }
        }
      }
      allHours {
        id
        submitted
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
          }
        }
      }
      hoursSummary {
        userId
        phaseId
        hours
      }
      billingSummary {
        projectId
        phaseId
        userId
        hours
      }
      build {
        frontEnd
      }
      wiki {
        id
        key
        dateCreated
        dateUpdated
        markdown
        createdBy
        locked
        orgId
      }
      tasks {
        id
        private
        projectId
        phaseId
        dateCreated
        dateUpdated
        dueDate
        status
        assignee
        createdBy
        orgId
        details
        title
        priority
        changeLog {
          date
          status
        }
      }
      leadPosts {
        title
        description
        score
        keywords
        names
        url
        error
        text
        dateCreated
        dateUpdated
        orgId
        userId
        id
        processed
        processDetails {
          action
          priority
          notes
          type
          projectName
        }
        scoreAI
        namesAI {
          name
          desc
          score
        }
        tokensAI
        alert
      }
      siteReports {
        id
        date
        orgId
        projectId
        createdBy
        markdown
        dateCreated
        dateUpdated
        siteProgresses {
          code
          progress
          markdown
          images {
            url
            caption
          }
        }
      }
      leadProjectNames
    }
  }
`;

export const QUERY_ITEMS_NO_ARTICLE_TEXT = gql`
  query queryItems($input: DefaultInput!) {
    queryItems(input: $input) {
      errors {
        message
        code
      }
      resourceTypes {
        id
        name
        rate
        orgId
        salary
        freelance
      }
      users {
        displayName
        email
        id
        orgId
        salary
        resourceType
        planned
        roles
        dateUpdated
        inactive
      }
      projects {
        id
        status
        orgId
        floorArea
        name
        code
        color
        dateCreated
        dateUpdated
        startDate
        profitPercentage
        constructionBudget
        version
        CSIs
        phases {
          name
          id
          active
          contractBudget
          fee
          duration
          startWeek
          startDate
          billable
          billType
          deleted
          resources {
            resourceType
            hoursPerWeek
          }
        }
        team {
          id
          resourceType
        }
        legacyTeam
        slug
        multiplier
        lockedKeys
        resourceTypes {
          id
          name
          rate
          salary
          freelance
        }
      }
      organization {
        multiplier
        name
        emailDomain
        id
      }
      hours {
        id
        submitted
        orgId
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
            note
          }
        }
      }
      allHours {
        id
        submitted
        userId
        sunday
      }
      hoursSummary {
        userId
        phaseId
        hours
      }
      billingSummary {
        projectId
        phaseId
        userId
        hours
      }
      build {
        frontEnd
      }
      wiki {
        id
        key
        dateCreated
        dateUpdated
        markdown
        createdBy
        locked
        orgId
        scoreAI
        namesAI {
          name
          desc
          score
        }
        tokensAI
      }
      leadPosts {
        title
        description
        score
        keywords
        names
        url
        error
        dateCreated
        dateUpdated
        orgId
        userId
        id
        scoreAI
        namesAI {
          name
          desc
          score
        }
        tokensAI
        alert
      }
    }
  }
`;

export const GET_BACKLOG = gql`
  query getBacklog($input: DefaultInput!) {
    getBacklog(input: $input) {
      errors {
        message
        code
      }
      backlogTasks {
        id
        private
        projectId
        phaseId
        dateCreated
        dueDate
        status
        assignee
        createdBy
        orgId
        details
        title
        priority
      }
    }
  }
`;

export const GET_DUPLICATE_TIMESHEETS = gql`
  query getDuplicateTimesheets($input: DefaultInput!) {
    getDuplicateTimesheets(input: $input) {
      errors {
        message
        code
      }
      allHours {
        id
        submitted
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
          }
        }
      }
    }
  }
`;

export const MUTATE_ITEM = gql`
  mutation mutateItem($input: DefaultInput!) {
    mutateItem(input: $input) {
      errors {
        message
        code
      }
      deleted
      resourceTypes {
        id
        name
        rate
        orgId
        salary
        freelance
      }
      users {
        displayName
        email
        id
        orgId
        salary
        resourceType
        planned
        roles
        inactive
      }
      projects {
        id
        status
        orgId
        name
        shortName
        floorArea
        code
        color
        dateCreated
        dateUpdated
        startDate
        profitPercentage
        constructionBudget
        version
        CSIs
        phases {
          billable
          billType
          active
          name
          id
          contractBudget
          fee
          duration
          startWeek
          startDate
          deleted
          resources {
            resourceType
            hoursPerWeek
          }
        }
        team {
          id
          resourceType
        }
        legacyTeam
        slug
        multiplier
        lockedKeys
        resourceTypes {
          id
          name
          rate
          salary
          freelance
        }
      }
      organization {
        multiplier
        name
        emailDomain
        id
      }
      hours {
        id
        submitted
        orgId
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
            note
          }
        }
      }
      tasks {
        id
        private
        projectId
        phaseId
        dateCreated
        dueDate
        status
        assignee
        createdBy
        orgId
        details
        title
        priority
      }
      wiki {
        id
        key
        dateCreated
        dateUpdated
        markdown
        createdBy
        locked
        orgId
      }
      leadPosts {
        title
        description
        score
        keywords
        names
        url
        error
        text
        dateCreated
        dateUpdated
        orgId
        userId
        id
        scoreAI
        namesAI {
          name
          desc
          score
        }
        tokensAI
        alert
      }
      siteReports {
        id
        date
        orgId
        projectId
        createdBy
        markdown
        dateCreated
        dateUpdated
        siteProgresses {
          code
          progress
          markdown
          images {
            url
            caption
          }
        }
      }
    }
  }
`;

export const BACKUP_DATABASE = gql`
  mutation mutateItem($input: DefaultInput!) {
    backupDatabase(input: $input) {
      errors {
        message
        code
      }
      backupLog {
        date
        dataSize
      }
    }
  }
`;

export const UNSUMBIT_HOURS = gql`
  mutation mutateItem($input: unsubmitTimesheetInput!) {
    unsubmitTimesheet(input: $input) {
      errors {
        message
        code
      }
      status
    }
  }
`;

export const GET_LAST_BACKUP = gql`
  query getLastBackup($input: DefaultInput!) {
    getLastBackup(input: $input) {
      errors {
        message
        code
      }
      backupLog {
        date
        dataSize
      }
    }
  }
`;

export const SUBMIT_EMAIL = gql`
  mutation submitEmail($input: EmailInput!) {
    submitEmail(input: $input) {
      errors {
        message
        code
      }
      status
    }
  }
`;

export const GET_USER = gql`
  query getUser($input: UserInput!) {
    getUser(input: $input) {
      errors {
        message
      }
      user {
        displayName
        email
        picture
        roles
        id
        salary
        orgId
        resourceType
        inactive
      }
      token
    }
  }
`;

export const QUERY_DEFAULT_HOLIDAYS = gql`
  query getDefaultHolidays($input: DefaultInput!) {
    getDefaultHolidays(input: $input) {
      errors {
        message
        code
      }
      defaultHolidays {
        date
        rule
        type
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($input: UserInput!) {
    getUsers(input: $input) {
      errors {
        message
      }
      users {
        displayName
        email
        picture
        roles
        id
        salary
        orgId
        resourceType
        inactive
      }
    }
  }
`;

export const GET_RESOURCE_TYPES = gql`
  query getResourceTypes($input: UserInput!) {
    getResourceTypes(input: $input) {
      errors {
        message
      }
      resourceTypes {
        id
        name
        rate
        orgId
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects($input: UserInput!) {
    getProjects(input: $input) {
      errors {
        message
      }
      projects {
        id
        orgId
        name
        code
        color
        dateCreated
        dateUpdated
        phases {
          name
          id
          contractBudget
        }
        team
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query getProject($token: String, $slug: String) {
    getProject(input: { token: $token, slug: $slug }) {
      errors {
        message
      }
      project {
        id
        name
        code
        color
        dateCreated
        dateUpdated
        CSIs
        phases {
          name
          id
          contractBudget
        }
        team
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateAdminProject($input: AdminProjectInput) {
    updateAdminProject(input: $input) {
      errors {
        message
      }
      project {
        id
        name
        color
        code
        dateCreated
        dateUpdated
        CSIs
        phases {
          name
          id
          contractBudget
        }
        team
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createAdminProject($input: AdminProjectInput) {
    createAdminProject(input: $input) {
      errors {
        message
      }
      project {
        id
        name
        color
        code
        dateCreated
        dateUpdated
        CSIs
        phases {
          name
          id
          contractBudget
        }
        team
      }
    }
  }
`;

export const GET_HOURS = gql`
  query getHoursWeek($input: UserInput!) {
    getHoursWeek(input: $input) {
      hours {
        id
        orgId
        userId
        sunday
        rows {
          projectId
          phaseId
          hours {
            count
            note
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const UPDATE_HOURS = gql`
  mutation updateHours($input: HoursSetInput!) {
    updateHours(input: $input) {
      errors {
        message
      }
      hours {
        userId
        sunday
        id
        orgId
        rows {
          projectId
          phaseId
          hours {
            count
            note
          }
        }
      }
    }
  }
`;

export const UPDATE_RESOURCE_TYPE = gql`
  mutation updateResourceType($input: ResourceTypeInput!) {
    updateResourceType(input: $input) {
      errors {
        message
      }
      resourceType {
        id
        orgId
        rate
        name
      }
    }
  }
`;

export const GET_HOURS_REPORT = gql`
  query getHoursReport(
    $token: String!
    $startDate: String!
    $endDate: String!
  ) {
    getHoursReport(
      input: { token: $token, startDate: $startDate, endDate: $endDate }
    ) {
      status
      hoursReportCSV
      errors {
        message
      }
    }
  }
`;

export const GET_PRESIGNED_POST = gql`
  query getS3PresignedPost($input: DefaultInput!) {
    getS3PresignedPost(input: $input) {
      presignedPostString
      errors {
        message
      }
    }
  }
`;
